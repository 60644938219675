import { useRef, useState } from 'react'
import Base from '@howdy/core/elements/Modal/Base'
import Button from '@howdy/core/elements/Button'
import TermsAndConditionsText from '../../components/TermsAndConditions'
import JsPDF from 'jspdf'
import Circle from '@howdy/core/elements/Circle'
import PenNib from '@howdy/core/elements/Icons/PenNib'

export default function TCModal({ display, acceptAction, closeAction }) {
  const termsRef = useRef(null)
  const [termsHtml, setTermsHtml] = useState(null)
  const generatePDF = async () => {
    if (termsHtml) {
      var getContent =
        '<div style="font-size:9px; padding: 22px 15px; width:450px;">' + termsHtml + '</div>'
      const doc = new JsPDF('p', 'px', 'a4', true)
      doc.html(getContent, {
        async callback(doc) {
          await doc.save('T&CHowdy.pdf')
        },
      })
    }
  }

  return (
    <Base isOpen={display} onRequestClose={closeAction}>
      <div className='relative'>
        <div className='flex w-full justify-center p-4'>
          <Circle borderColor='#DCDCDC' bgColor='white' className='text-howdy-primary-green'>
            <PenNib />
          </Circle>
        </div>
        <div
          ref={termsRef}
          className='h-[300px] max-w-[500px] overflow-y-auto p-4 text-sm text-[#464646] lg:h-[400px]'
        >
          <TermsAndConditionsText setHtml={setTermsHtml} />
        </div>
      </div>
      <div className='flex justify-center gap-x-5 p-4'>
        <div>
          <Button style='secondary' onClick={generatePDF}>
            Download
          </Button>
        </div>
        <div>
          <Button onClick={acceptAction}>Accept</Button>
        </div>
      </div>
    </Base>
  )
}
